import {
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconCash,
  IconCashOff,
  IconDotsVertical,
  IconEdit,
  IconFile,
  IconFileOff,
  IconFileText,
  IconPlus,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { CustomDateRangePicker } from '../../components/custom-date-range-picker';
import { CustomLoader } from '../../components/custom-loader';
import { Page } from '../../components/page';
import {
  useCancelCashFlow,
  useCashFlowRecharge,
  useCashFlowWithdrawal,
  useGenerateGenericCashFlowReceipt,
  useGetCashFlowTransactions,
  usePrintCashFlowReceipt,
  useSetCashFlowRoundedValue,
} from '../../data/hooks/cash-flow';
import { useGetUser } from '../../data/hooks/user';
import { useAuth } from '../../hooks/useAuth';
import useCashFlowContext from '../../hooks/useCashFlowContext';
import { UserRole, UserType } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { errorNotification } from '../../providers/mantine-notifications';
import {
  cashFlowTypeColor,
  cashFlowTypeHumanized,
} from '../../utils/constants/cash-flow';
import { CashFlowTransactionType } from '../../utils/enums/cash-flow';
import { formatBRL, realToUSCash } from '../../utils/helpers';

type FormFilterType = {
  page: number;
  limit: number;
  createdAt: string;
  name: string;
  value: string;
  showCancelled: boolean;
};

type RechargeFormType = {
  value: string;
  description: string;
  name: string;
};

type WithdrawalFormType = {
  value: string;
  description: string;
  name: string;
};

type GenericReceiptValueState = {
  value: string;
  description: string;
};

type PageModalState =
  | 'recharge'
  | 'withdrawal'
  | 'generic-receipt'
  | 'set-rounded-value'
  | 'confirm-refound'
  | 'confirm-receipt'
  | null;

function downloadFile(response: Blob, fileName: string) {
  const url = URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
}

export function CashFlowList() {
  const { user } = useAuth();
  const [genericReceiptValues, setGenericReceiptValues] = useState<
    GenericReceiptValueState[]
  >([{ description: 'INITIAL', value: 'INITIAL' }]);
  const { loading, fetch, response } = useGetCashFlowTransactions();
  const { balance, getBalance } = useCashFlowContext();
  const { loading: cashFlowRechargeLoad, fetch: cashFlowRechargeFetcher } =
    useCashFlowRecharge();
  const { fetch: getUsersFetcher, response: getUsersResponse } = useGetUser();
  const { fetch: cashFlowWithdrawalFetcher, loading: cashFlowWithdrawalLoad } =
    useCashFlowWithdrawal();
  const {
    fetch: genCashFlowReceiptFetcher,
    loading: genCashFlowReceiptLoader,
  } = useGenerateGenericCashFlowReceipt();
  const { fetch: printCashFlowFetcher, loading: printCashFlowLoader } =
    usePrintCashFlowReceipt();
  const { fetch: cancelCashFlowFetcher, loading: cancelCashFlowLoader } =
    useCancelCashFlow();
  const { fetch: setRoundedValueFetcher, loading: setRoundedValueLoader } =
    useSetCashFlowRoundedValue();
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [selectedToRoundValue, setSelectedToRoundValue] = useState(0);
  const [selectedCashFlow, setSelectedCashFlow] = useState<number>();
  const page = useRef(1);
  const limit = useRef(10);

  const isManagerViewer = user?.user.role === UserRole.MANAGER_VIEWER;

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      createdAt: '',
      name: '',
      value: '',
      showCancelled: false,
      limit: limit.current,
      page: page.current,
    },
  });

  const rechargeForm = useForm<RechargeFormType>({
    initialValues: {
      value: '',
      description: '',
      name: '',
    },
  });

  const withdrawalForm = useForm<WithdrawalFormType>({
    initialValues: {
      value: '',
      description: '',
      name: '',
    },
  });

  const addGenericReceiptValuesForm = useForm({
    initialValues: {
      value: '',
      description: '',
      transactionNote: '',
      username: '',
      total: '0.00',
    },
  });

  const toRoundedValueForm = useForm({
    initialValues: {
      roundedValue: '',
    },
  });

  async function getTransactions(isReset = false) {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    if (isReset) {
      await fetch({
        params: {
          page: 1,
          limit: 10,
          showCancelled: false,
        },
      });
    } else {
      await fetch({
        params: {
          ...validatedValues,
          createdAt:
            validatedValues['createdAt']?.[0] !== null
              ? validatedValues['createdAt']?.join(',')
              : undefined,
          value: validatedValues['value']
            ? realToUSCash(validatedValues['value'])
            : undefined,
          page: page.current,
          limit: limit.current,
        },
      });
    }
  }

  async function handlePageChange(newPage: number) {
    page.current = newPage;
    getTransactions();
  }

  async function handlePageLimitChange(newLimit: number) {
    limit.current = newLimit;
    getTransactions();
  }

  async function handleRecharge(values: typeof rechargeForm.values) {
    await cashFlowRechargeFetcher({
      data: {
        value: realToUSCash(values.value),
        description: values.description,
        name: values.name,
      },
      onSuccess: () => {
        setPageModal(null);
        rechargeForm.reset();
        getTransactions();
        getBalance();
      },
    });
  }

  async function handleWithdrawal(values: typeof withdrawalForm.values) {
    await cashFlowWithdrawalFetcher({
      data: {
        value: `-${realToUSCash(values.value)}`,
        description: values.description,
        name: values.name,
      },
      onSuccess: () => {
        setPageModal(null);
        withdrawalForm.reset();
        getTransactions();
        getBalance();
      },
    });
  }

  async function getUsers() {
    await getUsersFetcher();
  }

  async function handleCreateGenericReceipt() {
    const clonedList = genericReceiptValues;
    clonedList.splice(0, 1);

    await genCashFlowReceiptFetcher({
      data: {
        list: clonedList.map((item) => ({
          description: item.description,
          value: realToUSCash(item.value),
        })),
        total: addGenericReceiptValuesForm.values.total,
        transactionNote: addGenericReceiptValuesForm.values.transactionNote,
        username: addGenericReceiptValuesForm.values.username,
      },
      onSuccess: (res) => {
        downloadFile(
          res,
          `recibo-${String(new Date(Date.now()).valueOf()).slice(7, 11)}.pdf`,
        );
        setGenericReceiptValues([{ description: 'INITIAL', value: 'INITIAL' }]);
        addGenericReceiptValuesForm.reset();
        setPageModal(null);
        getBalance();
        getTransactions();
      },
    });
  }

  async function handlePrintReceipt(cashFlowId: number) {
    await printCashFlowFetcher({
      id: cashFlowId,
      onSuccess: (res) => {
        downloadFile(
          res,
          `copia-recibo-${String(new Date(Date.now()).valueOf()).slice(
            7,
            11,
          )}.pdf`,
        );
        setPageModal('confirm-receipt');
      },
    });
  }

  async function handleSetRoundedValue(baseValue: string) {
    const convertedBaseValue = Number(baseValue);
    const convertedRoundedValue = Number(
      realToUSCash(toRoundedValueForm.values.roundedValue),
    );

    const validatedBaseValue =
      convertedBaseValue < 0 ? convertedBaseValue * -1 : convertedBaseValue;
    const validatedConvertedValue =
      convertedRoundedValue < 0
        ? convertedRoundedValue * -1
        : convertedRoundedValue;

    if (validatedConvertedValue > validatedBaseValue + 5) {
      errorNotification({
        title: 'A diferença não pode ser maior que R$5,00',
        message: 'tente outro valor',
      });
      return;
    }

    await setRoundedValueFetcher({
      id: selectedToRoundValue,
      data: {
        roundedValue: realToUSCash(toRoundedValueForm.values.roundedValue),
      },
      onSuccess: () => {
        setSelectedToRoundValue(0);
        toRoundedValueForm.reset();
        getTransactions();
      },
    });
  }

  function updateTotalValue(value: string) {
    const convertedValue = Number(realToUSCash(value));
    const convertedTotal = Number(addGenericReceiptValuesForm.values.total);
    addGenericReceiptValuesForm.setValues({
      total: (convertedTotal + convertedValue).toFixed(2),
    });
  }

  function addGenericReceiptValues(item: GenericReceiptValueState) {
    if (item.description !== '' && item.value !== '') {
      setGenericReceiptValues([...genericReceiptValues, item]);
      updateTotalValue(item.value);
    }
    addGenericReceiptValuesForm.setValues({
      description: '',
      value: '',
    });
  }

  function removeGenericReceiptValues(description: string) {
    const cloneList = genericReceiptValues;
    const findIndex = cloneList.findIndex(
      (item) => item.description === description,
    );

    if (findIndex !== -1) {
      const convertedInverseValue =
        Number(realToUSCash(cloneList[findIndex].value)) * -1;
      updateTotalValue(formatBRL(convertedInverseValue.toFixed(2)));
      cloneList.splice(findIndex, 1);
      setGenericReceiptValues([...cloneList]);
    }
  }

  function setToRoundValue(id: number, roundedValue: string) {
    toRoundedValueForm.reset();
    setSelectedToRoundValue(id);
    toRoundedValueForm.setFieldValue('roundedValue', formatBRL(roundedValue));
  }

  function handleClearFilter() {
    formFilter.reset();
    getTransactions(true);
  }

  async function handleRefoundCashFlow() {
    if (selectedCashFlow) {
      await cancelCashFlowFetcher({ id: selectedCashFlow });
      getBalance();
      getTransactions(true);
      setPageModal(null);
    }
  }

  useEffect(() => {
    getTransactions();
    getBalance();
    getUsers();
  }, []);

  return (
    <Page title="Movimentação">
      <CustomLoader
        loading={
          loading ||
          cashFlowRechargeLoad ||
          cashFlowWithdrawalLoad ||
          genCashFlowReceiptLoader ||
          setRoundedValueLoader ||
          printCashFlowLoader ||
          cancelCashFlowLoader
        }
      />
      <Flex m={16} direction="column">
        <Flex align="center" justify="end">
          <Button
            color="ltpBlue.9"
            mr={8}
            leftIcon={<IconCash />}
            onClick={() => setPageModal('recharge')}
          >
            Entrada no caixa
          </Button>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button color="ltpBlue.7" leftIcon={<IconCashOff />}>
                Saída do caixa
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Tipos de saída</Menu.Label>
              <Menu.Item
                onClick={() => setPageModal('generic-receipt')}
                icon={<IconFile size={14} />}
              >
                Com recibo
              </Menu.Item>
              <Menu.Item
                onClick={() => setPageModal('withdrawal')}
                icon={<IconFileOff size={14} />}
              >
                Sem recibo
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Flex wrap="wrap" align="center" justify="start" mb={8}>
          <form onSubmit={formFilter.onSubmit(() => getTransactions())}>
            <Flex align="center" wrap="wrap">
              <CustomDateRangePicker
                required
                withAsterisk
                placeholder="selecione um intervalo"
                label="Intervalo"
                maw={170}
                mb={16}
                mr={8}
                name="createdAt"
                {...formFilter.getInputProps('createdAt')}
              />
              <Autocomplete
                label="Usuário"
                placeholder="comece a digitar um nome"
                data={getUsersResponse?.map((item) => item.username) ?? []}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('name')}
              />
              <TextInput
                label="Valor"
                placeholder="valor"
                type="text"
                mb={16}
                mr={8}
                value={formFilter.values.value}
                onChange={(e) =>
                  formFilter.setFieldValue('value', formatBRL(e.target.value))
                }
              />
              <Button mb={-9} color="ltpBlue.9" onClick={handleClearFilter}>
                Limpar
              </Button>
              <Button mb={-9} ml={16} type="submit">
                Filtrar
              </Button>
              <Checkbox
                label="Cancelados"
                name="showCancelled"
                color="blue"
                ml={8}
                mb={-5}
                checked={formFilter.values.showCancelled}
                {...formFilter.getInputProps('showCancelled')}
              />
            </Flex>
          </form>
        </Flex>
        <DataTable
          recordsPerPage={response?.meta.itemsPerPage ?? 5}
          recordsPerPageOptions={[10, 50, 100, 500]}
          onRecordsPerPageChange={(recordRange) =>
            handlePageLimitChange(recordRange)
          }
          onPageChange={handlePageChange}
          totalRecords={response?.meta.totalItems}
          page={page.current}
          fetching={loading}
          height="72vh"
          noRecordsText="Sem movimentação"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={response?.items}
          rowStyle={({ cancelledAt }) =>
            cancelledAt && {
              backgroundColor: 'rgba(255, 0, 0, 0.055)',
              color: 'red',
            }
          }
          columns={[
            {
              accessor: 'username',
              title: 'Funcionário',
            },
            {
              accessor: 'type',
              title: 'Tipo',
              render: ({ type }) => (
                <Badge color={cashFlowTypeColor[type]}>
                  {cashFlowTypeHumanized[type]}
                </Badge>
              ),
            },
            {
              accessor: 'status',
              title: '',
              render: ({ cancelledAt }) =>
                cancelledAt && <Badge color="red.7">Cancelado</Badge>,
            },
            {
              accessor: 'value',
              title: 'Valor',
              render: ({ value, cancelledAt }) => (
                <Text
                  fw="bold"
                  color={Number(value) < 0 ? 'red.7' : 'green.7'}
                  style={{ textDecoration: cancelledAt && 'line-through' }}
                >{`${formatBRL(String(value))}`}</Text>
              ),
            },
            {
              accessor: 'roundedValue',
              title: 'Valor Arredondado',
              render: ({ roundedValue, id, value, cancelledAt }) =>
                id === selectedToRoundValue ? (
                  <form
                    onSubmit={toRoundedValueForm.onSubmit(() =>
                      handleSetRoundedValue(value),
                    )}
                  >
                    <TextInput
                      onBlur={() => setSelectedToRoundValue(0)}
                      required
                      withAsterisk
                      placeholder="valor"
                      type="text"
                      name="roundedValue"
                      value={toRoundedValueForm.values.roundedValue}
                      onChange={(e) =>
                        toRoundedValueForm.setFieldValue(
                          'roundedValue',
                          formatBRL(`-${e.target.value}`),
                        )
                      }
                    />
                  </form>
                ) : (
                  <Text
                    fw="bold"
                    style={{ textDecoration: cancelledAt && 'line-through' }}
                    color={Number(roundedValue) < 0 ? 'red.7' : 'green.7'}
                  >{`${formatBRL(String(roundedValue))}`}</Text>
                ),
            },
            {
              accessor: 'name',
              title: 'Responsável/Solicitante',
            },
            {
              accessor: 'description',
              title: 'Descrição',
            },
            {
              accessor: 'createdAt',
              title: 'Data da Operação',
              render: ({ createdAt }) =>
                createdAt ? formatLocale(createdAt, 'DD/MM/YYYY HH:mm') : '--',
            },
            {
              accessor: 'createdAt',
              title: 'Cancelado em',
              render: ({ cancelledAt }) =>
                cancelledAt
                  ? formatLocale(cancelledAt, 'DD/MM/YYYY HH:mm')
                  : '--',
            },
            {
              accessor: 'createdAt',
              title: 'Cancelado por',
              render: ({ cancelledBy }) => cancelledBy,
            },
            {
              accessor: 'menu',
              title: '',
              render: ({ id, roundedValue, type, cancelledAt }) => (
                <Menu disabled={isManagerViewer}>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      disabled={type === CashFlowTransactionType.RECHARGE}
                      onClick={() => handlePrintReceipt(id)}
                      icon={<IconFileText size={14} />}
                    >
                      Imprimir
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        type === CashFlowTransactionType.RECHARGE ||
                        cancelledAt !== null
                      }
                      onClick={() => setToRoundValue(id, roundedValue ?? '')}
                      icon={<IconEdit size={14} />}
                    >
                      Setar Valor Arredondado
                    </Menu.Item>
                    <Menu.Item
                      disabled={
                        user?.user.type !== UserType.MASTER ||
                        cancelledAt !== null
                      }
                      onClick={() => {
                        setSelectedCashFlow(id);
                        setPageModal('confirm-refound');
                      }}
                      icon={<IconCashOff size={14} />}
                    >
                      Cancelar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
        <Group position="right">
          <Text>{`Caixa: ${formatBRL(balance)}`}</Text>
        </Group>
      </Flex>
      <Modal
        title="Entrada de valores ao caixa"
        opened={pageModal === 'recharge'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form onSubmit={rechargeForm.onSubmit(handleRecharge)}>
          <Grid columns={2} maw={500}>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor"
                placeholder="valor da adicionado"
                type="text"
                value={rechargeForm.values.value}
                onChange={(e) =>
                  rechargeForm.setFieldValue('value', formatBRL(e.target.value))
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Autocomplete
                label="Nome"
                placeholder="comece a digitar um nome"
                data={getUsersResponse?.map((item) => item.username) ?? []}
                {...rechargeForm.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Detalhes"
                placeholder="obs da entrada de valor"
                required
                withAsterisk
                {...rechargeForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={600}
        title="Criar recibo de saída"
        opened={pageModal === 'generic-receipt'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form
          onSubmit={addGenericReceiptValuesForm.onSubmit(
            handleCreateGenericReceipt,
          )}
        >
          <Grid columns={2}>
            <Grid.Col span={1}>
              <Autocomplete
                label="Usuário"
                placeholder="comece a digitar um nome"
                data={getUsersResponse?.map((item) => item.username) ?? []}
                {...addGenericReceiptValuesForm.getInputProps('username')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Text>Total</Text>
              <Text size={28} fw="bold">
                {formatBRL(addGenericReceiptValuesForm.values.total)}
              </Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <DataTable
                minHeight={200}
                noRecordsText="Sem valores"
                withBorder
                borderRadius="sm"
                striped
                highlightOnHover
                records={genericReceiptValues}
                columns={[
                  {
                    accessor: 'description',
                    title: 'Descrição',
                    render: ({ description }) =>
                      description === 'INITIAL' ? (
                        <TextInput
                          placeholder="descrição"
                          type="text"
                          name="description"
                          {...addGenericReceiptValuesForm.getInputProps(
                            'description',
                          )}
                        />
                      ) : (
                        description
                      ),
                  },
                  {
                    accessor: 'value',
                    title: 'Valor',
                    render: ({ value }) =>
                      value === 'INITIAL' ? (
                        <TextInput
                          placeholder="valor"
                          type="text"
                          name="value"
                          value={addGenericReceiptValuesForm.values.value}
                          onChange={(e) =>
                            addGenericReceiptValuesForm.setFieldValue(
                              'value',
                              formatBRL(e.target.value),
                            )
                          }
                        />
                      ) : (
                        value
                      ),
                    width: 120,
                  },
                  {
                    accessor: 'action',
                    title: '',
                    render: ({ description }) =>
                      description === 'INITIAL' ? (
                        <Button
                          type="button"
                          color="blue"
                          variant="light"
                          p={10}
                          onClick={() =>
                            addGenericReceiptValues(
                              addGenericReceiptValuesForm.values,
                            )
                          }
                        >
                          <IconPlus size={15} />
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          color="red"
                          variant="light"
                          p={10}
                          onClick={() =>
                            removeGenericReceiptValues(description)
                          }
                        >
                          <IconX size={15} />
                        </Button>
                      ),
                  },
                ]}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Detalhes"
                placeholder="obs que motivou retirada"
                required
                withAsterisk
                {...addGenericReceiptValuesForm.getInputProps(
                  'transactionNote',
                )}
              />
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Gerar Recibo</Button>
          </Group>
        </form>
      </Modal>
      <Modal
        title="Saída de valores do caixa"
        opened={pageModal === 'withdrawal'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <form onSubmit={withdrawalForm.onSubmit(handleWithdrawal)}>
          <Grid columns={2} maw={500}>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor"
                placeholder="valor da subtraído"
                type="text"
                value={withdrawalForm.values.value}
                onChange={(e) =>
                  withdrawalForm.setFieldValue(
                    'value',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Autocomplete
                label="Nome"
                placeholder="comece a digitar um nome"
                data={getUsersResponse?.map((item) => item.username) ?? []}
                {...withdrawalForm.getInputProps('name')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Detalhes"
                placeholder="obs que motivou retirada"
                required
                withAsterisk
                {...withdrawalForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group mt={16} mb={16} position="right">
            <Button type="submit">Salvar</Button>
          </Group>
        </form>
      </Modal>
      <Modal
        title="Confirmar"
        opened={pageModal === 'confirm-refound'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        closeOnEscape={false}
      >
        <Text fz="sm" mb={16}>
          Deseja realmente cancelar esta movimentação?
        </Text>
        <Button color="red" onClick={() => setPageModal(null)} mr={16}>
          Voltar
        </Button>
        <Button color="green" onClick={handleRefoundCashFlow}>
          Confirmar
        </Button>
      </Modal>
      <Modal
        title="Exportar Recibo."
        opened={pageModal === 'confirm-receipt'}
        onClose={() => setPageModal(null)}
        closeOnClickOutside={false}
        withCloseButton={false}
        closeOnEscape={false}
      >
        <Group>
          <Text fw="bold">Tudo certo!</Text>
          <Text>O recibo vai aparecer em instantes no seus Downloads.</Text>
        </Group>
        <Group position="right">
          <Button onClick={() => setPageModal(null)}>OK</Button>
        </Group>
      </Modal>
    </Page>
  );
}
